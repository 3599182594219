import React from 'react'
import '../styles.css'
import { Form, Button } from 'react-bootstrap'
import FormValidator from '../../modules/FormValidator'
import client from '../../modules/HttpClient'

class NewUsuarioComercio extends React.Component {

    state = {
        comercio_id: '',
        numero: '',
        email: '',
        pin: '',
        rut: '',
        nombre: '',
        invalidRUT: false,
        invalidEmail: false,
        invalidCelular: false,
        invalidNombre: false,
        invalidPIN: false,
        noConnection: false,
        isFirstRegistered: false
    }

    async componentDidMount(){
        this.setState({ comercio_id: this.props.id})
    }

    handleNombre = (n) => {
        const name = n.target.value;
        this.setState({
            nombre: n.target.value,
            invalidNombre: !FormValidator.validateNombre(name)
        })
    }


    handleRUT = (rut) => {
        const _RUT = rut.target.value;
        this.setState({
            rut: _RUT,
            invalidRUT: !FormValidator.validateRUT(_RUT)
        })
    }

    handleEmail = (e) => {
        const _email = e.target.value;
        this.setState({
            email: _email,
            invalidEmail: !FormValidator.validateEmail(_email)
        })
    }

    handleCelular = (c) => {
        const cel = c.target.value;
        this.setState({
            numero: cel,
            invalidCelular: !FormValidator.validateCelular(cel)
        })
    }

    handlePIN = (p) => {
        const _pin = p.target.value;
        this.setState({
            pin: _pin,
            invalidPIN: !FormValidator.validatePIN(_pin)
        })
    }

    isRegisterEnabled = () => {
        return (FormValidator.validateUsuarioComercio(this.state))
    }

    handleNewUsuarioComercio = async () => {
        
        const res = await client.add_usuariocomercio(this.state);

        console.log(res)

        if (!res.ok) {
            this.setState({
                noConnection: !res.ok,
            })
        }

        this.setState({
            isFirstRegistered: true
        });

    }

    showInfoAboutConnection = () => {
        const { noConnection, isFirstRegistered } = this.state;

        if (isFirstRegistered) {
            if (noConnection) {
                return (
                    <Form.Text className="text-danger">
                        Hubo un problema con el registro. Intenta de nuevo o más tarde.
                </Form.Text>
                )

            } else {
                return (
                    <Form.Text className="text-success">
                        ¡Usuario agregado!
                </Form.Text>
                )
            }
        } else {
            return (<React.Fragment />)
        }
    }

    handleBack = () => { this.props.history.push('/') }

    render() {
        const {
            invalidEmail,
            invalidRUT,
            invalidCelular,
            invalidNombre,
            invalidPIN,
        } = this.state;

        return (
            <div >
                <Form>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Celular</Form.Label>
                        <Form.Control placeholder='912345678' isInvalid={invalidCelular} onChange={this.handleCelular} maxLength={9} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>E-Mail</Form.Label>
                        <Form.Control placeholder="ejemplo@example.org" isInvalid={invalidEmail} onChange={this.handleEmail} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Nombre</Form.Label>
                        <Form.Control placeholder="Juan Pérez" isInvalid={invalidNombre} onChange={this.handleNombre} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>RUT</Form.Label>
                        <Form.Control placeholder="1.111.112-3" isInvalid={invalidRUT} onChange={this.handleRUT} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>PIN</Form.Label>
                        <Form.Control placeholder="1234" isInvalid={invalidPIN} onChange={this.handlePIN} maxLength={4} />
                    </Form.Group>
                    
                    <Button variant="success"
                        disabled={!this.isRegisterEnabled()}
                        onClick={this.handleNewUsuarioComercio}>
                        Crear
                    </Button>
                    <div>
                        {this.showInfoAboutConnection()}
                    </div>
                </Form>
            </div>)
    }
}

export default NewUsuarioComercio;