import React from 'react';
import { Modal } from 'react-bootstrap'
import EditarUsuarioComercio from './EditarUsuarioComercio'

class EditarUsuarioComercioModal extends React.Component {
    render() {
        return (
            <Modal
                {...this.props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Editar Usuario Comercio
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <EditarUsuarioComercio userToken={this.props.userToken} id={this.props.id} nombre={this.props.nombre} rut={this.props.rut} email={this.props.email} numero={this.props.numero}/>
                </Modal.Body>
            </Modal>
        );
    }
}

export default EditarUsuarioComercioModal;