import React from 'react'
import '../styles.css'
import { Form, Button } from 'react-bootstrap'
import FormValidator from '../../modules/FormValidator'
import client from '../../modules/HttpClient'

class Register extends React.Component {

    state = {
        nombre: '',
        rut_contribuyente: '',
        email: '',
        numero: '',
        direccion: '',
        invalidRUT: false,
        invalidEmail: false,
        invalidCelular: false,
        invalidNombre: false,
        invalidDireccion: false,
        noConnection: false,
        isFirstRegistered: false
    }

    handleNombre = (n) => {
        const name = n.target.value;
        this.setState({
            nombre: n.target.value,
            invalidNombre: !FormValidator.validateNombre(name)
        })
    }

    handleRUT = (rut) => {
        const _RUT = rut.target.value;
        this.setState({
            rut_contribuyente: _RUT,
            invalidRUT: !FormValidator.validateRUT(_RUT)
        })
    }

    handleEmail = (e) => {
        const _email = e.target.value;
        this.setState({
            email: _email,
            invalidEmail: !FormValidator.validateEmail(_email)
        })
    }

    handleCelular = (c) => {
        const cel = c.target.value;
        this.setState({
            numero: cel,
            invalidCelular: !FormValidator.validateCelular(cel)
        })
    }

    handleDireccion = (d) => {
        const dir = d.target.value;
        this.setState({
            direccion: dir,
            invalidDireccion: !FormValidator.validateDireccion(dir)
        })
    }

    isRegisterEnabled = () => {
        return (FormValidator.validateAllForm(this.state))
    }

    handleRegister = async () => {
        const res = await client.register(this.state);

        console.log(res)

        if (!res.ok) {
            this.setState({
                noConnection: !res.ok,
            })
        } else{
            this.props.updateusuarios()
        }

        this.setState({
            isFirstRegistered: true
        });

    }

    showInfoAboutConnection = () => {
        const { noConnection, isFirstRegistered } = this.state;

        if (isFirstRegistered) {
            if (noConnection) {
                return (
                    <Form.Text className="text-danger">
                        Hubo un problema con el registro. Intenta de nuevo o más tarde.
                </Form.Text>
                )

            } else {
                return (
                    <Form.Text className="text-success">
                        ¡Usuario agregado!
                </Form.Text>
                )
            }
        } else {
            return (<React.Fragment />)
        }
    }

    handleBack = () => { this.props.history.push('/') }

    render() {
        const {
            invalidEmail,
            invalidRUT,
            invalidCelular,
            invalidNombre,
            invalidDireccion,
        } = this.state;

        return (
            <div >
                <Form>
                    <Form.Group>
                        <Form.Label>Nombre</Form.Label>
                        <Form.Control placeholder="Juan Pérez" isInvalid={invalidNombre} onChange={this.handleNombre} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>RUT Contribuyente</Form.Label>
                        <Form.Control placeholder="1.111.112-3" isInvalid={invalidRUT} onChange={this.handleRUT} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>E-Mail</Form.Label>
                        <Form.Control placeholder="ejemplo@example.org" isInvalid={invalidEmail} onChange={this.handleEmail} />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Celular</Form.Label>
                        <Form.Control placeholder='912345678' isInvalid={invalidCelular} onChange={this.handleCelular} maxLength={9} />
                        {/*
                                <Form.Text className="text-muted">
                                    We'll never share your email with anyone else.
                                </Form.Text>
                                */}
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Dirección</Form.Label>
                        <Form.Control placeholder="Av.Calle #234" isInvalid={invalidDireccion} onChange={this.handleDireccion} />
                    </Form.Group>
                    <Button variant="success"
                        disabled={!this.isRegisterEnabled()}
                        onClick={this.handleRegister}>
                        Registrar
                    </Button>
                    <div>
                        {this.showInfoAboutConnection()}
                    </div>
                </Form>
            </div>)
    }
}

export default Register;