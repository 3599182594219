import React from 'react'
import client from '../../modules/HttpClient'
import { Table, Button } from 'react-bootstrap'
import EditarUsuarioComercioModal from './EditarUsuarioComercioModal'
import EditarPinUsuarioComercioModal from './EditarPinUsuarioComercioModal'

class UsuariosComercios extends React.Component {

    state = {
        usuarios: [],
        isEmpty: false,
        modalEditarUsuarioShow: false,
        modalEditarPinUsuarioShow: false,
        selectedID: 0,
        selectedRUT: "",
        selectedNumero: "",
        selectedNombre: "",
        selectedEmail: "",
    }

    getUsuariosByID = async(data) => {
        const res = client.getUsuariosByID(data)
        return (res)
    }

    getUsuarios = async () => {
        const authData = this.props.userToken
        const id = this.props.id;
        const req = { id, ...authData };
        var usuarios = await client.getUsuariosByID(req)
        const isUsuariosEmpty = usuarios.length === 0
        this.setState({ usuarios, isUsuariosEmpty, id })
    }

    newUser = () => {
        this.props.newuser()
    }

    async componentDidMount() {
        const id = this.props.id;
        const req = { id, ...this.props.userToken };

        var usuarios = await this.getUsuariosByID(req);
        const isEmpty = usuarios.length === 0

        this.setState({ usuarios })
        this.setState({ isEmpty })
    }

    modalEditarUsuarioClose = () => {
        this.setState({ modalEditarUsuarioShow: false })
        this.getUsuarios()
    };

    modalEditarUsuarioOpen = (id, rut, nombre, email, numero) => this.setState({ modalEditarUsuarioShow: true, selectedID: id, selectedRUT: rut, selectedEmail: email, selectedNombre: nombre, selectedNumero: numero });

    modalEditarPinUsuarioOpen = (id) => this.setState({modalEditarPinUsuarioShow: true, selectedID: id });

    modalEditarPinUsuarioClose = () => {
        this.setState({ modalEditarPinUsuarioShow: false })
        this.getUsuarios()
    };

    inhabilitarVendedor = async (id) => {
        const authData = this.props.userToken
        const req = { id, ...authData };
        const res = await client.inhabilitar_vendedor(req)
        if (res.ok){
            this.getUsuarios();
        }
    }

    habilitarVendedor = async (id) => {
        const authData = this.props.userToken
        const req = { id, ...authData };
        const res = await client.habilitar_vendedor(req)
        if (res.ok){
            this.getUsuarios();
        }
    }


    render() {
        const { usuarios, isEmpty } = this.state;

        return (
            <div>
                <EditarUsuarioComercioModal
                    show={this.state.modalEditarUsuarioShow}
                    onHide={this.modalEditarUsuarioClose}
                    userToken={this.props.userToken}
                    id ={this.state.selectedID}
                    rut={this.state.selectedRUT}
                    numero={this.state.selectedNumero}
                    nombre={this.state.selectedNombre}
                    email={this.state.selectedEmail}
                />

                <EditarPinUsuarioComercioModal
                    show={this.state.modalEditarPinUsuarioShow}
                    onHide={this.modalEditarPinUsuarioClose}
                    id={this.state.selectedID}
                    userToken={this.props.userToken}
                />
                {
                    !isEmpty ?
                        <Table>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Nombre</th>
                                    <th>Rut</th>
                                    <th>Número de teléfono</th>
                                    <th>Fecha de creación</th>
                                    <th>Fecha de modificación</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    usuarios.map((u) => {
                                        return (
                                            <tr key={u.id}>
                                                <td>{u.id}</td>
                                                <td>{u.nombre}</td>
                                                <td>{u.rut}</td>
                                                <td>{u.numero}</td>
                                                <td>{u.created_at}</td>
                                                <td>{u.updated_at}</td>
                                                <td>
                                                    <Button variant="secondary" onClick={ () => this.modalEditarUsuarioOpen(u.id, u.rut, u.nombre, u.email, u.numero)}>Editar</Button>
                                                    <Button variant="secondary" onClick={ () => this.modalEditarPinUsuarioOpen(u.id)}>Cambiar PIN</Button>
                                                    { ( u.habilitado)    
                                                        ? (<Button variant="secondary" onClick={() => this.inhabilitarVendedor(u.id)}>Inhabilitar</Button>)
                                                        : (<Button variant="secondary" onClick={() => this.habilitarVendedor(u.id)}>Habilitar</Button>)
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table> : <React.Fragment>No hay nada :(</React.Fragment>
                }
                <div>
                    <Button variant="success" onClick={ this.newUser }>Crear</Button>
                </div>
            </div>
            
        )
    }
}

export default UsuariosComercios;