import React from 'react'
import '../styles.css'
import client from '../../modules/HttpClient'

class DatosBancarios extends React.Component {

    state = {
        banco: '',
        nombre: '',
        rut: '',
        email: '',
        tipo_cuenta: '',
        numero_cuenta: '',
        monto: 0,
    }

    getDatosBancarios = async (data) => {
        const res = client.getDatosBancariosByRUT(data)
        return (res)
    }

    async componentDidMount() {
        const rut = this.props.rut;
        const monto = this.props.monto;
        const req = { rut, ...this.props.userToken };
        var datos_bancarios = await this.getDatosBancarios(req);
        this.setState({ monto:  monto})
        this.setState({ banco: datos_bancarios.banco, numero_cuenta: datos_bancarios.numero_cuenta, tipo_cuenta: datos_bancarios.tipo_cuenta, rut: datos_bancarios.rut, nombre: datos_bancarios.titular });

    }

    render() {
        const {
            banco,
            nombre,
            rut,
            tipo_cuenta,
            numero_cuenta,
            monto
        } = this.state;

        return (
            <div >
                <div>
                    <h3>Nombre:</h3>
                    <h3>{nombre}</h3>
                </div>
                <div>
                    <h3>Rut:</h3>
                    <h3>{rut}</h3>
                </div>
                <div>
                    <h3>Banco:</h3>
                    <h3>{banco}</h3>
                </div>
                <div>
                    <h3>Tipo cuenta:</h3>
                    <h3>{tipo_cuenta}</h3>
                </div>
                <div>
                    <h3>Número cuenta:</h3>
                    <h3>{numero_cuenta}</h3>
                </div>
                <div>
                    <h3>Monto transacción:</h3>
                    <h3>{monto}</h3>
                </div>
            </div>)
    }
}

export default DatosBancarios;