import React from 'react'
import '../styles.css'
import { Form, Button } from 'react-bootstrap'
import FormValidator from '../../modules/FormValidator'
import client from '../../modules/HttpClient'

class EditarPinUsuarioComercio extends React.Component {

    state = {
        comercio_id: '',
        pin: '',
        invalidPIN: false,
        noConnection: false,
        isFirstRegistered: false
    }

    async componentDidMount(){
        this.setState({ comercio_id: this.props.id})
    }

    handlePIN = (p) => {
        const _pin = p.target.value;
        this.setState({
            pin: _pin,
            invalidPIN: !FormValidator.validatePIN(_pin)
        })
    }

    isRegisterEnabled = () => {
        return (FormValidator.validatePIN(this.state.pin))
    }

    handlePINChange = async () => {

        const id_usuario_comercio = this.state.comercio_id;
        const pin_nuevo = this.state.pin;
        const req = {id_usuario_comercio, pin_nuevo, ...this.props.userToken}
        
        const res = await client.cambiar_pin_usuario_comercio(req);

        console.log(res)

        if (!res.ok) {
            this.setState({
                noConnection: !res.ok,
            })
        }

        this.setState({
            isFirstRegistered: true
        });

    }

    showInfoAboutConnection = () => {
        const { noConnection, isFirstRegistered } = this.state;

        if (isFirstRegistered) {
            if (noConnection) {
                return (
                    <Form.Text className="text-danger">
                        Hubo un problema al cambiar el PIN. Intenta de nuevo o más tarde.
                </Form.Text>
                )

            } else {
                return (
                    <Form.Text className="text-success">
                        ¡PIN actualizado!
                </Form.Text>
                )
            }
        } else {
            return (<React.Fragment />)
        }
    }

    handleBack = () => { this.props.history.push('/') }

    render() {
        const {
            invalidPIN
        } = this.state;

        return (
            <div >
                <Form>
                    <Form.Group>
                        <Form.Label>PIN</Form.Label>
                        <Form.Control placeholder="1234" isInvalid={invalidPIN} onChange={this.handlePIN} maxLength={4} />
                    </Form.Group>
                    <Button variant="success"
                        disabled={!this.isRegisterEnabled()}
                        onClick={this.handlePINChange}>
                        Cambiar PIN
                    </Button>
                    <div>
                        {this.showInfoAboutConnection()}
                    </div>
                </Form>
            </div>)
    }
}

export default EditarPinUsuarioComercio;