import React from 'react';
import { Table, Nav, Navbar, Form, FormControl, Button } from 'react-bootstrap'
import DatePicker from "react-datepicker";
import client from '../modules/HttpClient'
import NewUsuarioComercioModal from './HomeComponents/NewUsuarioComercioModal'
import CambiarContrasenaModal from './HomeComponents/CambiarContrasenaModal'
import EditarDatosBancariosModal from './HomeComponents/EditarDatosBancariosModal'
import EditarUsuarioComercioModal from './HomeComponents/EditarUsuarioComercioModal'
import EditarPinUsuarioComercioModal from './HomeComponents/EditarPinUsuarioComercioModal'
import "react-datepicker/dist/react-datepicker.css";
var dateFormat = require('dateformat');


class Comercio extends React.Component{

    state = {
        id: 0,
        email: '',
        modalCambiarContrasenaShow: false,
        modalEditarDatosBancariosShow: false,
        navUsuariosShow: true,
        navBoletasShow: false,
    }


    async componentDidMount() {
        this.setState({ id: this.props.id, email: this.props.dataUser.administrador.email})
    }

    navUsuariosOpen = () => this.setState({ navUsuariosShow: true, navBoletasShow: false });
    navBoletasOpen = () => this.setState({ navUsuariosShow: false, navBoletasShow: true });

    modalCambiarContrasenaOpen = () => this.setState({ modalCambiarContrasenaShow: true });
    modalCambiarContrasenaClose = () => this.setState({ modalCambiarContrasenaShow: false });

    modalEditarDatosBancariosOpen = () => this.setState({ modalEditarDatosBancariosShow: true });
    modalEditarDatosBancariosClose = () => this.setState({ modalEditarDatosBancariosShow: false });

    render() {
        return(
            <div>
                <Navbar bg="dark" variant="dark">
                    <Navbar.Brand>PagoCash</Navbar.Brand>
                    <Nav className="mr-auto">
                        <Nav.Link onClick={ () => this.navUsuariosOpen() }>Vendedores</Nav.Link>
                        <Nav.Link onClick={ () => this.navBoletasOpen() } >Boletas</Nav.Link>
                    </Nav>
                    <Form inline>
                        <Button variant="secondary" onClick={ () => this.modalEditarDatosBancariosOpen() }>Datos Bancarios</Button>
                        <Button variant="secondary" onClick={ () => this.modalCambiarContrasenaOpen() }>Cambiar Contraseña</Button>
                        <Button variant="danger" onClick={() => this.props.logOff()}>Cerrar sesión</Button>
                    </Form>
                </Navbar>

                <CambiarContrasenaModal 
                    show={this.state.modalCambiarContrasenaShow}
                    onHide={this.modalCambiarContrasenaClose}
                    userToken={this.props.dataUser.access_token}
                    email={this.state.email}
                />

                <EditarDatosBancariosModal 
                    show={this.state.modalEditarDatosBancariosShow}
                    onHide={this.modalEditarDatosBancariosClose}
                    userToken={this.props.dataUser.access_token}
                    id={this.state.id}
                />

                {this.state.navUsuariosShow &&
                    <UsuariosTable 
                        id={this.props.id}
                        userToken={this.props.dataUser.access_token}
                    />
                }

                {this.state.navBoletasShow &&
                    <BoletasTable 
                        id={this.props.id}
                        userToken={this.props.dataUser.access_token}
                    />
                }
            </div>
        )
    }

}

class UsuariosTable extends React.Component{


    state = {
        id: 0,
        usuarios: [],
        usuarios_filtered: [],
        isUsuariosEmpty: false,
        modalCrearUsuarioShow: false,
        modalEditarUsuarioShow: false,
        modalEditarPinUsuarioShow: false,
        selectedID: 0,
        selectedNombre: '',
        selectedRUT: '',
        selectedEmail: '',
        selectedNumero: ''
    }


    handleUsuariosSearch = (s) => {
        let currentList = [];
        let newList = [];

        if (s.target.value !== "") {
            currentList = this.state.usuarios;

            newList = currentList.filter( (c) => {
                const nombre = c.nombre.toLowerCase();
                const numero = c.numero;
                const rut = c.rut;
                const email = c.email;
                const filter = s.target.value.toLowerCase();
                if (nombre.includes(filter) || numero.includes(filter) || rut.includes(filter) || email.includes(filter)){
                    return c;
                }
            });
        } else {
            newList = this.state.usuarios;
        }

        this.setState({ usuarios_filtered: newList});
    }

    getUsuarios = async () => {
        const authData = this.props.userToken
        const id = this.props.id;
        const req = { id, ...authData };
        var usuarios = await client.getUsuariosByID(req)
        const isUsuariosEmpty = usuarios.length === 0
        this.setState({ usuarios, usuarios_filtered: usuarios, isUsuariosEmpty, id })
    }

    async componentDidMount() {
        this.getUsuarios();
    }

    modalCrearUsuarioClose = () => {
        this.setState({ modalCrearUsuarioShow: false })
        this.getUsuarios()
    };

    modalCrearUsuarioOpen = () => this.setState({ modalCrearUsuarioShow: true });

    modalEditarUsuarioClose = () => {
        this.setState({ modalEditarUsuarioShow: false })
        this.getUsuarios()
    };

    modalEditarUsuarioOpen = (id, rut, nombre, email, numero) => this.setState({ modalEditarUsuarioShow: true, selectedID: id, selectedRUT: rut, selectedEmail: email, selectedNombre: nombre, selectedNumero: numero });

    modalEditarPinUsuarioOpen = (id) => this.setState({modalEditarPinUsuarioShow: true, selectedID: id });

    modalEditarPinUsuarioClose = () => {
        this.setState({ modalEditarPinUsuarioShow: false })
        this.getUsuarios()
    };


    render(){
        return(
            <div>
                <Navbar bg="light" variant="light">
                    <Navbar.Brand>Vendedores</Navbar.Brand>
                    <Nav className="mr-sm-2">
                    </Nav>
                    <Form inline>
                        <FormControl type="text" placeholder="Search" onChange={this.handleUsuariosSearch} className="mr-sm-2" />
                        <Button variant="secondary" onClick={ () => this.modalCrearUsuarioOpen() }>Crear Usuario</Button>
                    </Form>
                </Navbar>

                <NewUsuarioComercioModal
                    show={this.state.modalCrearUsuarioShow}
                    onHide={this.modalCrearUsuarioClose}
                    userToken={this.props.userToken}
                    id={this.state.id}
                />

                <EditarUsuarioComercioModal
                    show={this.state.modalEditarUsuarioShow}
                    onHide={this.modalEditarUsuarioClose}
                    userToken={this.props.userToken}
                    id ={this.state.selectedID}
                    rut={this.state.selectedRUT}
                    numero={this.state.selectedNumero}
                    nombre={this.state.selectedNombre}
                    email={this.state.selectedEmail}
                />

                <EditarPinUsuarioComercioModal
                    show={this.state.modalEditarPinUsuarioShow}
                    onHide={this.modalEditarPinUsuarioClose}
                    id={this.state.selectedID}
                    userToken={this.props.userToken}
                />

                {
                    !this.state.isUsuariosEmpty ?
                        <Table>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Nombre</th>
                                    <th>Rut</th>
                                    <th>Número de teléfono</th>
                                    <th>Correo electrónico</th>
                                    <th>Fecha de creación</th>
                                    <th>Fecha de modificación</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.usuarios_filtered.map((u) => {
                                        return (
                                            <tr key={u.id}>
                                                <td>{u.id}</td>
                                                <td>{u.nombre}</td>
                                                <td>{u.rut}</td>
                                                <td>{u.numero}</td>
                                                <td>{u.email}</td>
                                                <td>{u.created_at}</td>
                                                <td>{u.updated_at}</td>
                                                <td>
                                                    <Button variant="secondary" onClick={ () => this.modalEditarUsuarioOpen(u.id, u.rut, u.nombre, u.email, u.numero)}>Editar</Button>
                                                    <Button variant="secondary" onClick={ () => this.modalEditarPinUsuarioOpen(u.id)}>Cambiar PIN</Button>
                                                    { ( u.habilitado)    
                                                        ? (<Button variant="secondary" onClick={() => this.inhabilitarVendedor(u.id)}>Inhabilitar</Button>)
                                                        : (<Button variant="secondary" onClick={() => this.habilitarVendedor(u.id)}>Habilitar</Button>)
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table> : <React.Fragment>No hay nada :(</React.Fragment>
                }
            </div>
            
        )
    }
}

class BoletasTable extends React.Component{

    state = {
        id: 0,
        startDate: new Date(),
        endDate: new Date(),
        boletas: [],
        isBoletasEmpty: false,
    }

    getBoletas = async () => {
        const authData = this.props.userToken
        const id = this.props.id
        const req = { id, ...authData };
        var boletas = await client.getBoletasByID(req)
        const isBoletasEmpty = boletas.length === 0
        this.setState({ boletas, isBoletasEmpty })
    }

    sendResumen = async () => {

        const comercio_id = this.props.id
        const fecha_inicio = dateFormat(this.state.startDate,"yyyy-mm-dd") + "T00:00:00.000Z";
        const fecha_termino = dateFormat(this.state.endDate,"yyyy-mm-dd") + "T00:00:00.000Z";
        const url = client.ENVIAR_RESUMEN_URL + "?comercio_id="  + comercio_id + "&fecha_inicio=" + fecha_inicio + "&fecha_termino=" + fecha_termino;
        window.open(url);
    }

    async componentDidMount() {
        this.getBoletas();
    }

    handleChangeStart = (date) => {
        this.setState({
            startDate: date,
        })
    }
     
    handleChangeEnd = (date) => {
        this.setState({
            endDate: date,
        })
    }

    render(){
        return(
            <div>
                <Navbar bg="light" variant="light">
                    <Navbar.Brand>Boletas</Navbar.Brand>
                    <Nav className="mr-sm-2">
                    </Nav>
                    <Form inline>
                        <DatePicker
                            dateFormat="dd/MM/yyyy"
                            selected={this.state.startDate}
                            selectsStart
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            onChange={this.handleChangeStart}
                        />

                        <DatePicker
                            dateFormat="dd/MM/yyyy"
                            selected={this.state.endDate}
                            selectsEnd
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            onChange={this.handleChangeEnd}
                            minDate={this.state.startDate}
                        />
                        <Button variant="secondary" onClick={ () => this.sendResumen()}>Solicitar Resumen</Button>
                    </Form>
                    
                </Navbar>

                {
                    !this.state.isBoletasEmpty ?
                        <Table>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Folio</th>
                                    <th>Medio de pago</th>
                                    <th>Monto total</th>
                                    <th>Código transacción</th>
                                    <th>Fecha de creación</th>
                                    <th>Fecha de modificación</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.boletas.map((b) => {
                                        if (b.medio_de_pago === 2){
                                            return (
                                                <tr key={b.id}>
                                                    <td>{b.id}</td>
                                                    <td>{b.folio}</td>
                                                    <td>Aplicación</td>
                                                    <td>{b.monto_total}</td>
                                                    <td>-</td>
                                                    <td>{b.created_at}</td>
                                                    <td>{b.updated_at}</td>
                                                </tr>
                                            )
                                        } else if (b.medio_de_pago === 3){
                                            return (
                                                <tr key={b.id}>
                                                    <td>{b.id}</td>
                                                    <td>{b.folio}</td>
                                                    <td>Tarjeta</td>
                                                    <td>{b.info_medio_pago}</td>
                                                    <td>{b.monto_total}</td>
                                                    <td>{b.created_at}</td>
                                                    <td>{b.updated_at}</td>
                                                </tr>
                                            )
                                        } else{
                                            return (
                                                <tr key={b.id}>
                                                    <td>{b.id}</td>
                                                    <td>{b.folio}</td>
                                                    <td>Efectivo</td>
                                                    <td>{b.monto_total}</td>
                                                    <td>-</td>
                                                    <td>{b.created_at}</td>
                                                    <td>{b.updated_at}</td>
                                                </tr>
                                            )
                                        }
                                    })
                                }
                            </tbody>
                        </Table> : <React.Fragment>No hay nada :(</React.Fragment>
                }
            </div>
        )
    }
}

export default Comercio;