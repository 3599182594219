import React from 'react'
import client from '../../modules/HttpClient'
import { Table } from 'react-bootstrap'

class Boletas extends React.Component {

    state = {
        boletas: [],
        isEmpty: false,
    }

    getBoletasByID = async (data) => {
        const res = client.getBoletasByID(data)
        return (res)
    }

    async componentDidMount() {
        const id = this.props.id
        const req = { id, ...this.props.userToken };

        const boletas = await this.getBoletasByID(req);
        const isEmpty = boletas.length === 0

        this.setState({ boletas })
        this.setState({ isEmpty })
    }

    render() {
        const { boletas, isEmpty } = this.state;

        return (
            <div>
                {
                    !isEmpty ?
                        <Table>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Folio</th>
                                    <th>Medio de pago</th>
                                    <th>Monto total</th>
                                    <th>Número transacción</th>
                                    <th>Fecha de creación</th>
                                    <th>Fecha de modificación</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    boletas.map((b) => {
                                        if (b.medio_de_pago === 2){
                                            return (
                                                <tr key={b.id}>
                                                    <td>{b.id}</td>
                                                    <td>{b.folio}</td>
                                                    <td>Aplicación</td>
                                                    <td>{b.monto_total}</td>
                                                    <td>-</td>
                                                    <td>{b.created_at}</td>
                                                    <td>{b.updated_at}</td>
                                                </tr>
                                            )
                                        } else if (b.medio_de_pago === 3){
                                            return (
                                                <tr key={b.id}>
                                                    <td>{b.id}</td>
                                                    <td>{b.folio}</td>
                                                    <td>Tarjeta</td>
                                                    <td>{b.monto_total}</td>
                                                    <td>{b.info_medio_pago}</td>
                                                    <td>{b.created_at}</td>
                                                    <td>{b.updated_at}</td>
                                                </tr>
                                            )
                                        } else{
                                            return (
                                                <tr key={b.id}>
                                                    <td>{b.id}</td>
                                                    <td>{b.folio}</td>
                                                    <td>Efectivo</td>
                                                    <td>{b.monto_total}</td>
                                                    <td>-</td>
                                                    <td>{b.created_at}</td>
                                                    <td>{b.updated_at}</td>
                                                </tr>
                                            )
                                        }
                                    })
                                }
                            </tbody>
                        </Table> : <React.Fragment>No hay nada :(</React.Fragment>
                }
            </div>
        )
    }
}

export default Boletas;