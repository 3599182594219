import React from 'react'
import '../styles.css'
import { Form, Button } from 'react-bootstrap'
import FormValidator from '../../modules/FormValidator'
import client from '../../modules/HttpClient'

class NewUsuarioComercio extends React.Component {

    state = {
        email: '',
        pass_antiguo: '',
        pass_nuevo: '',
        pass_nuevo2: '',
        invalidOldPassword: false,
        invalidPassword1: false,
        invalidPassword2: false,
        noConnection: false,
        isFirstRegistered: false
    }

    async componentDidMount(){
        this.setState({ email: this.props.email})
    }

    handleOldPassword = (p) => {
        const _PASS = p.target.value;
        this.setState({
            pass_antiguo: _PASS,
            invalidOldPassword: !FormValidator.validatePassword(_PASS)
        })    }

    handleNewPassword1 = (p) => {
        const _PASS = p.target.value;
        this.setState({
            pass_nuevo: _PASS,
            invalidPassword1: !FormValidator.validatePassword(_PASS)
        })
    }

    handleNewPassword2 = (p) => {
        const _PASS = p.target.value;
        this.setState({
            pass_nuevo2: _PASS,
            invalidPassword2: !(FormValidator.validatePassword2(_PASS, this.state.pass_nuevo))
        })    }

    isRegisterEnabled = () => {
        return (FormValidator.validateChangePassword(this.state))
    }

    handleChangePassword = async () => {
        
        const email = this.state.email
        const pass_antiguo = this.state.pass_antiguo
        const pass_nuevo = this.state.pass_nuevo
        const req = { email, pass_antiguo, pass_nuevo, ...this.props.userToken };
        
        const res = await client.change_admin_password(req);

        console.log(res)

        if (!res.ok) {
            this.setState({
                noConnection: !res.ok,
            })
        }

        this.setState({
            isFirstRegistered: true
        });

    }

    showInfoAboutConnection = () => {
        const { noConnection, isFirstRegistered } = this.state;

        if (isFirstRegistered) {
            if (noConnection) {
                return (
                    <Form.Text className="text-danger">
                        Hubo un problema actualizando su contraseña. Intentelo nuevamente.
                </Form.Text>
                )

            } else {
                return (
                    <Form.Text className="text-success">
                        ¡Contraseña Actualizada!
                </Form.Text>
                )
            }
        } else {
            return (<React.Fragment />)
        }
    }

    handleBack = () => { this.props.history.push('/') }

    render() {
        const { invalidOldPassword, invalidPassword1, invalidPassword2} = this.state;

        return (
            <div >
                <Form>
                    <Form.Group>
                        <Form.Label>Contraseña Anterior</Form.Label>
                        <Form.Control type="password" placeholder="********" isInvalid={invalidOldPassword} onChange={this.handleOldPassword} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Nueva Contraseña</Form.Label>
                        <Form.Control type="password" placeholder="********" isInvalid={invalidPassword1} onChange={this.handleNewPassword1} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Confirmar Nueva Contraseña</Form.Label>
                        <Form.Control type="password" placeholder="********" isInvalid={invalidPassword2} onChange={this.handleNewPassword2} />
                    </Form.Group>
                    
                    <Button variant="success"
                        disabled={!this.isRegisterEnabled()}
                        onClick={this.handleChangePassword}>
                        Cambiar
                    </Button>
                    <div>
                        {this.showInfoAboutConnection()}
                    </div>
                </Form>
            </div>)
    }
}

export default NewUsuarioComercio;