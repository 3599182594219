import React from 'react'
import '../styles.css'
import { Form, Button } from 'react-bootstrap'
import FormValidator from '../../modules/FormValidator'
import client from '../../modules/HttpClient'

class EditarUsuarioComercio extends React.Component {

    state = {
        id_usuario_comercio: '',
        numero: '',
        email: '',
        rut: '',
        nombre: '',
        invalidRUT: false,
        invalidEmail: false,
        invalidCelular: false,
        invalidNombre: false,
        noConnection: false,
        isFirstRegistered: false
    }

    async componentDidMount(){
        this.setState({ id_usuario_comercio: this.props.id, nombre: this.props.nombre, rut: this.props.rut, numero: this.props.numero, email: this.props.email})
    }

    handleNombre = (n) => {
        const name = n.target.value;
        this.setState({
            nombre: n.target.value,
            invalidNombre: !FormValidator.validateNombre(name)
        })
    }


    handleRUT = (rut) => {
        const _RUT = rut.target.value;
        this.setState({
            rut: _RUT,
            invalidRUT: !FormValidator.validateRUT(_RUT)
        })
    }

    handleEmail = (e) => {
        const _email = e.target.value;
        this.setState({
            email: _email,
            invalidEmail: !FormValidator.validateEmail(_email)
        })
    }

    handleCelular = (c) => {
        const cel = c.target.value;
        this.setState({
            numero: cel,
            invalidCelular: !FormValidator.validateCelular(cel)
        })
    }

    isRegisterEnabled = () => {
        return (FormValidator.validateUsuarioComercio2(this.state))
    }

    handleEditUsuarioComercio = async () => {

        const id_usuario_comercio = this.state.id_usuario_comercio
        const rut = this.state.rut
        const nombre = this.state.nombre
        const email = this.state.email
        const numero = this.state.numero
        const req = { id_usuario_comercio, rut, nombre, numero, email, ...this.props.userToken };
        const res = await client.actualizar_datos_usuario_comercio(req);

        if (!res.ok) {
            this.setState({
                noConnection: !res.ok,
            })
        }

        this.setState({
            isFirstRegistered: true
        });

    }

    showInfoAboutConnection = () => {
        const { noConnection, isFirstRegistered } = this.state;

        if (isFirstRegistered) {
            if (noConnection) {
                return (
                    <Form.Text className="text-danger">
                        Hubo un problema con el registro. Intenta de nuevo o más tarde.
                </Form.Text>
                )

            } else {
                return (
                    <Form.Text className="text-success">
                        ¡Usuario agregado!
                </Form.Text>
                )
            }
        } else {
            return (<React.Fragment />)
        }
    }

    handleBack = () => { this.props.history.push('/') }

    render() {
        const {
            invalidEmail,
            invalidRUT,
            invalidCelular,
            invalidNombre
        } = this.state;

        return (
            <div >
                <Form>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Celular</Form.Label>
                        <Form.Control placeholder={this.props.numero} value={this.state.numero} isInvalid={invalidCelular} onChange={this.handleCelular} maxLength={9} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>E-Mail</Form.Label>
                        <Form.Control placeholder={this.props.email} value={this.state.email} isInvalid={invalidEmail} onChange={this.handleEmail} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Nombre</Form.Label>
                        <Form.Control placeholder={this.props.nombre} isInvalid={invalidNombre} value={this.state.nombre} onChange={this.handleNombre} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>RUT</Form.Label>
                        <Form.Control placeholder={this.props.rut} isInvalid={invalidRUT} value={this.state.rut} onChange={this.handleRUT} />
                    </Form.Group>
                    
                    <Button variant="success"
                        disabled={!this.isRegisterEnabled()}
                        onClick={this.handleEditUsuarioComercio}>
                        Editar
                    </Button>
                    <div>
                        {this.showInfoAboutConnection()}
                    </div>
                </Form>
            </div>)
    }
}

export default EditarUsuarioComercio;