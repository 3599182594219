import Configuration from '../config/config'

class HttpClient {
    static LOGIN_URL = Configuration.APP_URL + '/login_admin';
    static COMERCIOS_URL = Configuration.APP_URL + '/consultar/comercios';
    static CLIENTES_URL = Configuration.APP_URL + '/consultar/clientes';
    static TRANSACCIONES_URL = Configuration.APP_URL + '/consultar/transacciones';
    static REGISTER_URL = Configuration.APP_URL + '/crear/comercio';
    static AGREGAR_USUARIO_COMERCIO_URL = Configuration.APP_URL + '/crear/usuario_comercio';
    static BOLETAS_URL = Configuration.APP_URL + '/consultar/boletas';
    static USUARIOS_COMERCIO_URL = Configuration.APP_URL + '/consultar/usuarios_comercios';
    static DATOS_BANCARIOS_URL = Configuration.APP_URL + '/consultar/datos_bancarios';
    static DATOS_BANCARIOS_COMERCIO_URL = Configuration.APP_URL + '/consultar/datos_bancarios_comercio';
    static ACTUALIZAR_TRANSACCION_URL = Configuration.APP_URL + '/pago/actualizar_transaccion';
    static INHABILITAR_COMERCIO_URL = Configuration.APP_URL + '/contrato/comercio/inhabilitar';
    static CAMBIAR_CONTRASENA_ADMIN_URL = Configuration.APP_URL + '/actualizar_datos_admin/password';
    static CAMBIAR_CONTRASENA_ADMIN_ALOMALDITO_URL = Configuration.APP_URL + '/actualizar_datos_admin/passwordalomaldito';
    static ACTUALIZAR_DATOS_BANCARIOS_URL = Configuration.APP_URL + '/actualizar_datos_admin/bancario';
    static ACTUALIZAR_DATOS_USUARIO_COMERCIO_URL = Configuration.APP_URL + '/actualizar_datos_admin/usuario_comercio';
    static CAMBIAR_PIN_USUARIO_COMERCIO_URL = Configuration.APP_URL + '/actualizar_datos_admin/usuario_comercio/pin';
    static ACTUALIZAR_AUTHORIZATION_URL = Configuration.APP_URL + '/actualizar_datos_admin/comercio/authorization';
    static INHABILITAR_VENDEDOR_URL = Configuration.APP_URL + '/contrato/usuario_comercio/inhabilitar';
    static HABILITAR_VENDEDOR_URL = Configuration.APP_URL + '/contrato/usuario_comercio/habilitar';
    static ENVIAR_RESUMEN_URL = Configuration.APP_URL + '/dte/boleta/reporte_excel';

    static getComercios = async ({ type, token }) => {
        const auth = toFirstUpperCase(type) + " " + token

        var data = await fetch(HttpClient.COMERCIOS_URL, {
            method: 'GET',
            withCredentials: true,
            credentials: 'include',
            headers: {
                'Authorization': auth,
                'Content-Type': 'application/json'
            }
        });

        var comercios = data.json();
        return (comercios)
    }

    static getClientes = async ({ type, token }) => {
        const auth = toFirstUpperCase(type) + " " + token

        var data = await fetch(HttpClient.CLIENTES_URL, {
            method: 'GET',
            withCredentials: true,
            credentials: 'include',
            headers: {
                'Authorization': auth,
                'Content-Type': 'application/json'
            }
        });

        var clientes = data.json();
        return (clientes)
    }

    static getTransacciones = async ({ type, token }) => {
        const auth = toFirstUpperCase(type) + " " + token

        var data = await fetch(HttpClient.TRANSACCIONES_URL, {
            method: 'GET',
            withCredentials: true,
            credentials: 'include',
            headers: {
                'Authorization': auth,
                'Content-Type': 'application/json'
            }
        });

        var transacciones = data.json();
        return (transacciones)
    }

    static getBoletasByID = async ({ type, token, id }) => {
        const auth = toFirstUpperCase(type) + " " + token

        var data = await fetch(HttpClient.BOLETAS_URL + "?comercio_id=" + String(id), {
            method: 'GET',
            withCredentials: true,
            credentials: 'include',
            headers: {
                'Authorization': auth,
                'Content-Type': 'application/json'
            }
        });

        var boletas = data.json();
        return (boletas)
    }

    static getDatosBancariosByRUT = async({ type, token, rut }) => {
        const auth = toFirstUpperCase(type) + " " + token

        var data = await fetch(HttpClient.DATOS_BANCARIOS_URL + "?rut_usuario=" + String(rut),{
            method: 'GET',
            withCredentials: true,
            credentials: 'include',
            headers: {
                'Authorization': auth,
                'Content-Type': 'application/json'
            }
        });

        var datos = data.json();
        return (datos)
    }

    static getDatosBancariosByID = async({ type, token, id }) => {
        const auth = toFirstUpperCase(type) + " " + token

        var data = await fetch(HttpClient.DATOS_BANCARIOS_COMERCIO_URL + "?comercio_id=" + String(id),{
            method: 'GET',
            withCredentials: true,
            credentials: 'include',
            headers: {
                'Authorization': auth,
                'Content-Type': 'application/json'
            }
        });

        var datos = data.json();
        return (datos)
    }

    static getUsuariosByID = async ({ type, token, id}) => {
        const auth = toFirstUpperCase(type) + " " + token

        var data = await fetch(HttpClient.USUARIOS_COMERCIO_URL + "?comercio_id=" + String(id), {
            method: 'GET',
            withCredentials: true,
            credentials: 'include',
            headers: {
                'Authorization': auth,
                'Content-Type': 'application/json'
            }
        });
        var usuarios = data.json();
        return (usuarios)
    }

    static login = async ({ email, password }) => {
        var myHeaders = new Headers({
            'Content-Type': 'application/json'
        });
        const userData = await fetch(HttpClient.LOGIN_URL, {
            method: 'POST',
            body: JSON.stringify({ email, password }),
            headers: myHeaders
        });

        var userDataJSON = await userData.json()
        return (userDataJSON)
    }

    static register = async ({ nombre, rut_contribuyente, email, numero, direccion }) => {
        var data = await fetch(HttpClient.REGISTER_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ nombre, rut_contribuyente, email, numero, direccion }),
        });

        if (data.ok) {
            var registerData = await data.json();
            return ({ ...registerData, ok: true })
        } else {
            return ({ ok: false, registerData: {} })
        }
    }

    static add_usuariocomercio = async ({ comercio_id, numero, email, pin, rut, nombre }) => {
        var data = await fetch(HttpClient.AGREGAR_USUARIO_COMERCIO_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ comercio_id, numero, email, pin, rut, nombre }),
        });

        if (data.ok) {
            var registerData = await data.json();
            return ({ ...registerData, ok: true })
        } else {
            return ({ ok: false, registerData: {} })
        }
    }

    static change_admin_password = async ({ type, token, email, pass_antiguo, pass_nuevo}) => {
        const auth = toFirstUpperCase(type) + " " + token
        const content = JSON.stringify({ email, pass_antiguo, pass_nuevo})

        var data = await fetch(HttpClient.CAMBIAR_CONTRASENA_ADMIN_URL, {
            method: 'POST',
            withCredentials: true,
            credentials: 'include',
            headers: {
                'Authorization': auth,
                'Content-Type': 'application/json'
            },
            body: content
        });
        if (data.ok) {
            return ({ ok: true })
        } else {
            return ({ ok: false })
        }
    }

    static sendResumenByID = async ({ type, token, comercio_id, fecha_inicio, fecha_termino}) => {
        const auth = toFirstUpperCase(type) + " " + token
        const content = JSON.stringify({ comercio_id, fecha_inicio, fecha_termino})

        var data = await fetch(HttpClient.ENVIAR_RESUMEN_URL, {
            method: 'POST',
            withCredentials: true,
            credentials: 'include',
            headers: {
                'Authorization': auth,
                'Content-Type': 'application/json'
            },
            body: content
        });
        if (data.ok) {
            return ({ ok: true })
        } else {
            return ({ ok: false })
        }
    }


    static change_comercio_password = async ({ type, token, email, pass_nuevo}) => {
        const auth = toFirstUpperCase(type) + " " + token
        const content = JSON.stringify({ email, pass_nuevo})

        var data = await fetch(HttpClient.CAMBIAR_CONTRASENA_ADMIN_ALOMALDITO_URL, {
            method: 'POST',
            withCredentials: true,
            credentials: 'include',
            headers: {
                'Authorization': auth,
                'Content-Type': 'application/json'
            },
            body: content
        });
        if (data.ok) {
            return ({ ok: true })
        } else {
            return ({ ok: false })
        }

    }

    static set_authorization = async ({ type, token, comercio_id, authorization}) => {
        const auth = toFirstUpperCase(type) + " " + token
        const content = JSON.stringify({ comercio_id, authorization})

        var data = await fetch(HttpClient.ACTUALIZAR_AUTHORIZATION_URL, {
            method: 'POST',
            withCredentials: true,
            credentials: 'include',
            headers: {
                'Authorization': auth,
                'Content-Type': 'application/json'
            },
            body: content
        });
        if (data.ok) {
            return ({ ok: true })
        } else {
            return ({ ok: false })
        }

    }

    static actualizar_datos_bancarios = async ({ type, token, id_comercio, rut, nombre, banco, tipo_cuenta, numero_cuenta }) => {
        const auth = toFirstUpperCase(type) + " " + token
        const content = JSON.stringify({ id_comercio, rut, nombre, banco, tipo_cuenta, numero_cuenta})

        var data = await fetch(HttpClient.ACTUALIZAR_DATOS_BANCARIOS_URL, {
            method: 'POST',
            withCredentials: true,
            credentials: 'include',
            headers: {
                'Authorization': auth,
                'Content-Type': 'application/json'
            },
            body: content
        });
        if (data.ok) {
            return ({ ok: true })
        } else {
            return ({ ok: false })
        }
    }
    
    static actualizar_datos_usuario_comercio = async ({ type, token, id_usuario_comercio, numero, nombre, email, rut }) => {
        const auth = toFirstUpperCase(type) + " " + token
        const content = JSON.stringify({ id_usuario_comercio, numero, nombre, email, rut })

        var data = await fetch(HttpClient.ACTUALIZAR_DATOS_USUARIO_COMERCIO_URL, {
            method: 'POST',
            withCredentials: true,
            credentials: 'include',
            headers: {
                'Authorization': auth,
                'Content-Type': 'application/json'
            },
            body: content
        });
        if (data.ok) {
            return ({ ok: true })
        } else {
            return ({ ok: false })
        }
    }

    static cambiar_pin_usuario_comercio = async ({ type, token, id_usuario_comercio, pin_nuevo }) => {
        const auth = toFirstUpperCase(type) + " " + token
        const content = JSON.stringify({ id_usuario_comercio, pin_nuevo })

        var data = await fetch(HttpClient.CAMBIAR_PIN_USUARIO_COMERCIO_URL, {
            method: 'POST',
            withCredentials: true,
            credentials: 'include',
            headers: {
                'Authorization': auth,
                'Content-Type': 'application/json'
            },
            body: content
        });
        if (data.ok) {
            return ({ ok: true })
        } else {
            return ({ ok: false })
        }
    }

    static actualizar_transaccion = async ({type, token, id}) => {
        const auth = toFirstUpperCase(type) + " " + token

        var data = await fetch(HttpClient.ACTUALIZAR_TRANSACCION_URL, {
            method: 'POST',
            withCredentials: true,
            credentials: 'include',
            headers: {
                'Authorization': auth,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ id })
        });
        if (data.ok) {
            return ({ok: true})
        } else{
            return ({ ok: false})
        }
    }

    static inhabilitar_comercio = async ({type, token, id}) => {
        const auth = toFirstUpperCase(type) + " " + token

        var data = await fetch(HttpClient.INHABILITAR_COMERCIO_URL + "?comercio_id=" + String(id), {
            method: 'GET',
            withCredentials: true,
            credentials: 'include',
            headers: {
                'Authorization': auth,
                'Content-Type': 'application/json'
            },
        });
        if (data.ok) {
            return ({ok: true})
        } else{
            return ({ ok: false})
        }
    }

    static habilitar_vendedor = async ({type, token, id}) => {
        const auth = toFirstUpperCase(type) + " " + token

        var data = await fetch(HttpClient.HABILITAR_VENDEDOR_URL + "?usuario_comercio_id=" + String(id), {
            method: 'GET',
            withCredentials: true,
            credentials: 'include',
            headers: {
                'Authorization': auth,
                'Content-Type': 'application/json'
            },
        });        
        if (data.ok) {
            return ({ok: true})
        } else{
            return ({ ok: false})
        }
    }

    static inhabilitar_vendedor = async ({type, token, id}) => {
        const auth = toFirstUpperCase(type) + " " + token

        var data = await fetch(HttpClient.INHABILITAR_VENDEDOR_URL + "?usuario_comercio_id=" + String(id), {
            method: 'GET',
            withCredentials: true,
            credentials: 'include',
            headers: {
                'Authorization': auth,
                'Content-Type': 'application/json'
            },
        });
        if (data.ok) {
            return ({ok: true})
        } else{
            return ({ ok: false})
        }
    }
}

const toFirstUpperCase = (s) => {
    return (s.charAt(0).toUpperCase() + s.slice(1))
}

export default HttpClient