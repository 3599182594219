import rutRegex from 'rut-regex';

class FormValidator {
    static validateRUT = (rut_contribuyente) => {
        return (rutRegex({ exact: true, dot: true, hyphen: true }).test(String(rut_contribuyente)));
    }
    static validateEmail = (email) => {
        var regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regex.test(String(email).toLowerCase());
    }
    static validateCelular = (celular) => {
        return (!isNaN(celular) && String(celular).length > 8 );
    }

    static validateCuenta = (cuenta) => {
        return (!isNaN(cuenta) && String(cuenta).length > 8 );
    }

    static validatePIN = (pin) => {
        return (!isNaN(pin) && String(pin).length === 4);
    }

    static validatePassword = (password) => {
        return( String(password).length > 7 );
    }

    static validatePassword2 = (password, confirmation) => {
        return ( String(password).length > 7 && String(password) === String(confirmation))
    }

    static validateNombre = (nombre) => {
        const regex = /\d/;
        return (!regex.test(nombre));
    }

    static validateDireccion = (direccion) => {
        return( String(direccion).length > 0 )
    }

    static validateAllForm = ({ rut_contribuyente, email, numero, nombre, direccion }) => {
        return (
            FormValidator.validateRUT(rut_contribuyente) && FormValidator.validateEmail(email) &&
            FormValidator.validateCelular(numero) && FormValidator.validateNombre(nombre) && 
            FormValidator.validateDireccion(direccion)
        )
    }

    static validateUsuarioComercio = ({numero, email, rut, nombre, pin}) => {
        return (
            FormValidator.validateCelular(numero) && FormValidator.validateEmail(email) &&
            FormValidator.validateRUT(rut) && FormValidator.validateNombre(nombre) &&
            FormValidator.validatePIN(pin)
        )
    }

    static validateUsuarioComercio2 = ({numero, email, rut, nombre}) => {
        return (
            FormValidator.validateCelular(numero) && FormValidator.validateEmail(email) &&
            FormValidator.validateRUT(rut) && FormValidator.validateNombre(nombre)
        )
    }

    static validateChangePassword = ({pass_antiguo, pass_nuevo, pass_nuevo2}) => {
        return (
            FormValidator.validatePassword(pass_antiguo) && FormValidator.validatePassword(pass_nuevo) &&
            FormValidator.validatePassword2(pass_nuevo2, pass_nuevo)
        )
    }

    static validateChangePassword2 = ({pass_nuevo, pass_nuevo2}) => {
        return (
            FormValidator.validatePassword(pass_nuevo) &&
            FormValidator.validatePassword2(pass_nuevo2, pass_nuevo)
        )
    }

    static validateDatosBancarios =({nombre, rut, numero_cuenta}) => {
        return(
            FormValidator.validateNombre(nombre) && FormValidator.validateRUT(rut) &&
            FormValidator.validateCuenta(numero_cuenta)
        )
    }
}

export default FormValidator;