import React from 'react';
import { Form, Button, Card } from 'react-bootstrap'
import Home from './Home'
import Comercio from './Comercio'
import './styles.css'
import HttpClient from '../modules/HttpClient'

class Main extends React.Component {

    state = {
        email: "",
        password: "",
        id: 0,
        data: null,
        logged: false,
    }

    handleLogin = async () => {

        var userDataJSON = await HttpClient.login({
            email: this.state.email,
            password: this.state.password
        })

        // manejo de estados
        var id_comercio = 0
        var login = false
        if (userDataJSON.administrador){
            id_comercio = userDataJSON.administrador.comercio_id
            login = true
        }

        this.setState({
            data: userDataJSON,
            logged: login,
            id: id_comercio

        })
    }

    handleChangeEmail = (c) => {
        this.setState({ email: c.target.value })
    }

    handleChangePassword = (p) => {
        this.setState({ password: p.target.value })
    }

    LogOff = () => { this.setState({ logged: !this.state.logged })}

    render() {
        const { id, data, logged } = this.state

        return (
            <div>
                {!logged 
                    ? ( <div className='Login'>
                            <Card>
                                <Card.Body>
                                    <Form>
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Label>Correo electrónico</Form.Label>
                                            <Form.Control placeholder='admin@pagocash.cl' onChange={this.handleChangeEmail} />
                                        </Form.Group>
                                        <Form.Group controlId="formBasicPassword">
                                            <Form.Label>Contraseña</Form.Label>
                                            <Form.Control type="password" placeholder="********" onChange={this.handleChangePassword} />
                                        </Form.Group>
                                        <Button variant="outline-primary" onClick={this.handleLogin}>
                                            Ingresar
                                        </Button>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </div>) 
                    : (
                        id === 0
                        ? (<Home dataUser={data} logOff ={ () => this.LogOff() }/>)
                        : (<Comercio dataUser={data} logOff={ () => this.LogOff()} id={id}/>)
                    )
                }
            </div>
        )
    }
}

export default Main;