import React from 'react'
import '../styles.css'
import { Form, Button } from 'react-bootstrap'
import FormValidator from '../../modules/FormValidator'
import client from '../../modules/HttpClient'

class CambiarContrasenaComercio extends React.Component {

    state = {
        id: '',
        authorization: '',
        invalidPassword1: false,
        noConnection: false,
        isFirstRegistered: false
    }

    async componentDidMount(){
        this.setState({ email: this.props.email})
    }


    handleNewPassword1 = (a) => {
        const _AUTH = a.target.value;
        this.setState({
            authorization: _AUTH,
            invalidPassword1: !FormValidator.validatePassword(_AUTH)
        })
    }

    isRegisterEnabled = () => {
        return (FormValidator.validatePassword(this.state.authorization))
    }

    handleAuthorization = async () => {
        
        const comercio_id = this.props.id
        const authorization = this.state.authorization
        const req = { comercio_id, authorization, ...this.props.userToken };
        
        const res = await client.set_authorization(req);

        console.log(res)

        if (!res.ok) {
            this.setState({
                noConnection: !res.ok,
            })
        }
        else{
            this.props.updateusuarios()
        }

        this.setState({
            isFirstRegistered: true
        });

    }

    showInfoAboutConnection = () => {
        const { noConnection, isFirstRegistered } = this.state;

        if (isFirstRegistered) {
            if (noConnection) {
                return (
                    <Form.Text className="text-danger">
                        Hubo un problema actualizando el código. Intentelo nuevamente.
                </Form.Text>
                )

            } else {
                return (
                    <Form.Text className="text-success">
                        ¡Código Actualizada!
                </Form.Text>
                )
            }
        } else {
            return (<React.Fragment />)
        }
    }

    handleBack = () => { this.props.history.push('/') }

    render() {
        const { invalidPassword1, invalidPassword2} = this.state;

        return (
            <div >
                <Form>
                    <Form.Group>
                        <Form.Label>Código</Form.Label>
                        <Form.Control placeholder="123456789" isInvalid={invalidPassword1} onChange={this.handleNewPassword1} />
                    </Form.Group>
                    
                    <Button variant="success"
                        disabled={!this.isRegisterEnabled()}
                        onClick={this.handleAuthorization}>
                        Actualizar
                    </Button>
                    <div>
                        {this.showInfoAboutConnection()}
                    </div>
                </Form>
            </div>)
    }
}

export default CambiarContrasenaComercio;