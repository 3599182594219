import React from 'react';
import { Modal } from 'react-bootstrap'
import Boletas from './Boletas'

class BoletasModal extends React.Component {
    render() {
        return (
            <Modal
                {...this.props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Boletas del comercio
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Boletas userToken={this.props.userToken} id={this.props.id} />
                </Modal.Body>
            </Modal>
        );
    }
}

export default BoletasModal;