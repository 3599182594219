import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import Main from './components/Main'

class App extends React.Component {
  render() {
    return (
      <div>
        <Router>
          <Route path="/" component={Main} />
        </Router>
      </div>
    );
  }
}


export default App;
