import React from 'react'
import '../styles.css'
import { Form, Button } from 'react-bootstrap'
import FormValidator from '../../modules/FormValidator'
import client from '../../modules/HttpClient'

class EditarDatosBancarios extends React.Component {

    state = {
        id_comercio: '',
        nombre: '',
        banco: '',
        tipo_cuenta: '',
        rut: '',
        numero_cuenta: '',
        edit: false,
        invalidRUT: false,
        invalidEmail: false,
        invalidCuenta: false,
        invalidNombre: false,
        invalidPIN: false,
        noConnection: false,
        isFirstRegistered: false
    }

    getDatosBancarios = async (data) => {
        const res = client.getDatosBancariosByID(data)
        return (res)
    }

    async componentDidMount(){
        const id = this.props.id
        const req = { id, ...this.props.userToken };
        var datos_bancarios = await this.getDatosBancarios(req);
        this.setState({ banco: datos_bancarios.banco, numero_cuenta: datos_bancarios.numero_cuenta, tipo_cuenta: datos_bancarios.tipo_cuenta, rut: datos_bancarios.rut, nombre: datos_bancarios.titular });
        this.setState({ id_comercio: this.props.id})
    }

    handleNombre = (n) => {
        const name = n.target.value;
        this.setState({
            nombre: n.target.value,
            invalidNombre: !FormValidator.validateNombre(name)
        })
    }


    handleRUT = (rut) => {
        const _RUT = rut.target.value;
        this.setState({
            rut: _RUT,
            invalidRUT: !FormValidator.validateRUT(_RUT)
        })
    }

    handleBanco = (banco) => {
        const _BANCO = banco.target.value;
        this.setState({ banco: _BANCO })
    }

    handleTipoCuenta = (tipo) => {
        const _TIPO = tipo.target.value;
        this.setState({ tipo_cuenta: _TIPO })
    }

    handleCuenta = (c) => {
        const cuenta = c.target.value;
        this.setState({
            numero_cuenta: cuenta,
            invalidCuenta: !FormValidator.validateCuenta(cuenta)
        })
    }

    isRegisterEnabled = () => {
        return (FormValidator.validateDatosBancarios(this.state))
    }

    enableEdit = () => { this.setState({ edit: true }) };

    handleUpdateBankData = async () => {

        const id_comercio = this.state.id_comercio
        const rut = this.state.rut
        const nombre = this.state.nombre
        const banco = this.state.banco
        const tipo_cuenta = this.state.tipo_cuenta
        const numero_cuenta = this.state.numero_cuenta
        const req = { id_comercio, rut, nombre, banco, tipo_cuenta, numero_cuenta, ...this.props.userToken };
        
        const res = await client.actualizar_datos_bancarios(req);

        console.log(res)

        if (!res.ok) {
            this.setState({
                noConnection: !res.ok,
            })
        }

        this.setState({
            isFirstRegistered: true
        });

    }

    showInfoAboutConnection = () => {
        const { noConnection, isFirstRegistered } = this.state;

        if (isFirstRegistered) {
            if (noConnection) {
                return (
                    <Form.Text className="text-danger">
                        Hubo un problema editando los datos bancarios. Intenta de nuevo más tarde.
                </Form.Text>
                )

            } else {
                return (
                    <Form.Text className="text-success">
                        ¡Datos actualizados!
                </Form.Text>
                )
            }
        } else {
            return (<React.Fragment />)
        }
    }

    handleBack = () => { this.props.history.push('/') }

    render() {
        const {
            invalidRUT,
            invalidCuenta,
            invalidNombre,
        } = this.state;

        return (
            <div >
                <Form>
                    <Form.Group>
                        <Form.Label>Nombre</Form.Label>
                        <Form.Control placeholder={this.state.nombre} value={this.state.nombre} disabled={!this.state.edit} isInvalid={invalidNombre} onChange={this.handleNombre} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>RUT</Form.Label>
                        <Form.Control placeholder={this.state.rut} value={this.state.rut} disabled={!this.state.edit} isInvalid={invalidRUT} onChange={this.handleRUT} />
                    </Form.Group>
                    <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label>Banco</Form.Label>
                        <Form.Control as="select" value={this.state.banco} onChange={this.handleBanco} disabled={!this.state.edit}>
                        <option>Banco BICE</option>
                        <option>Banco Consorcio</option>
                        <option>Banco Corpbanca</option>
                        <option>Banco BCI</option>
                        <option>Banco Estado</option>
                        <option>Banco Falabella</option>
                        <option>Banco Internacional</option>
                        <option>Banco Paris</option>
                        <option>Banco Ripley</option>
                        <option>Banco Santander</option>
                        <option>Banco Santander Banefe</option>
                        <option>Banco Security</option>
                        <option>Banco de Chile/ Edwards-Citi</option>
                        <option>Banco del Desarrollo</option>
                        <option>Coopeuch</option>
                        <option>HSBC Bank</option>
                        <option>Itaú</option>
                        <option>Rabobank</option>
                        <option>Scotiabank</option>
                        <option>Scotiabank Azul</option>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="exampleForm.ControlSelect2">
                    <Form.Label>Tipo cuenta</Form.Label>
                        <Form.Control as="select" value={this.state.tipo_cuenta} onChange={this.handleTipoCuenta} disabled={!this.state.edit}>
                        <option>Chequera Electrónica</option>
                        <option>Cuenta de Ahorro</option>
                        <option>Cuenta Corriente</option>
                        <option>Cuenta Vista</option>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Número de cuenta</Form.Label>
                        <Form.Control placeholder={this.state.numero_cuenta} value={this.state.numero_cuenta} isInvalid={invalidCuenta} disabled={!this.state.edit} onChange={this.handleCuenta} />
                    </Form.Group>
                    <Button variant="success" disabled={!this.isRegisterEnabled()} onClick={ this.handleUpdateBankData}>Crear</Button>
                    <Button variant="secondary" onClick={this.enableEdit}>Habilitar edición</Button>
                    <div>
                        {this.showInfoAboutConnection()}
                    </div>
                </Form>
            </div>)
    }
}

export default EditarDatosBancarios;