import React from 'react';
import { Table, Nav, Navbar, Form, FormControl, Button } from 'react-bootstrap'
import client from '../modules/HttpClient'
import RegisterModal from './HomeComponents/RegisterModal'
import BoletasModal from './HomeComponents/BoletasModal'
import DatosBancariosModal from './HomeComponents/DatosBancariosModal';
import UsuariosComerciosModal from './HomeComponents/UsuariosComerciosModal';
import NewUsuarioComercioModal from './HomeComponents/NewUsuarioComercioModal';
import CambiarContrasenaModal from './HomeComponents/CambiarContrasenaModal';
import CambiarContrasenaComercioModal from './HomeComponents/CambiarContrasenaComercioModal';
import EditarDatosBancariosModal from './HomeComponents/EditarDatosBancariosModal';
import SetearAuthorizationModal from './HomeComponents/SetearAuthorizationModal';

class Home extends React.Component {

    state = {
        modalCambiarContrasenaShow: false,
        navComerciosShow: true,
        navClientesShow: false,
        navTransaccionesShow: false,
        isAdmin: false,
    }

    async componentDidMount() {
        this.setState({ isAdmin: this.props.dataUser.administrador.is_admin, email: this.props.dataUser.administrador.email})
    }

    modalCambiarContrasenaClose = () => this.setState({ modalCambiarContrasenaShow: false });

    modalCambiarContrasenaOpen = () => this.setState({ modalCambiarContrasenaShow: true });
   
    navClientesOpen = () => this.setState({ navComerciosShow: false, navClientesShow: true, navTransaccionesShow: false });

    navTransaccionesOpen = () => this.setState({ navComerciosShow: false, navTransaccionesShow: true, navClientesShow: false });

    navComerciosOpen = () => this.setState({ navComerciosShow: true, navClientesShow: false, navTransaccionesShow: false });

    render() {
        const { isAdmin } = this.state
        return (
            <div>
                <Navbar bg="dark" variant="dark">
                    <Navbar.Brand>PagoCash</Navbar.Brand>
                    <Nav className="mr-auto">
                        <Nav.Link onClick={ () => this.navComerciosOpen() }>Comercios</Nav.Link>
                        <Nav.Link onClick={ () => this.navClientesOpen() } >Clientes</Nav.Link>
                        <Nav.Link onClick={ () => this.navTransaccionesOpen() }>Transacciones</Nav.Link>
                        <Nav.Link onClick={ () => this.modalCambiarContrasenaOpen() }>Cambiar Contraseña</Nav.Link>
                    </Nav>
                    <Form inline>
                        <Button variant="danger" onClick={() => this.props.logOff()}>Cerrar sesión</Button>
                    </Form>
                </Navbar>

                <CambiarContrasenaModal
                    show={this.state.modalCambiarContrasenaShow}
                    onHide={this.modalCambiarContrasenaClose}
                    userToken={this.props.dataUser.access_token}
                    email={this.state.emailSelected}
                />

                {this.state.navComerciosShow &&
                    <ComerciosTable
                        userToken={this.props.dataUser.access_token}
                        isAdmin={this.state.isAdmin}
                    />
                }

                {this.state.navClientesShow &&
                    <ClientesTable
                        userToken={this.props.dataUser.access_token}
                    />
                }

                {this.state.navTransaccionesShow &&
                    <TransaccionesTable
                        userToken={this.props.dataUser.access_token}
                    />
                }
                
                
            </div>
        );
    }
}

class ComerciosTable extends React.Component{

    state = {
        comercios: [],
        comercios_filtered: [],
        modalBoletasShow: false,
        modalCrearUsuarioShow: false,
        modalEditarComercioShow: false,
        modalEditarDatosBancariosShow: false,
        modalUsuariosShow: false,
        modalRegisterShow: false,
        modalCambiarContrasenaShow: false,
        modalSetearAuthorizationShow: false,
        emailSelected: "",
        idComercioSelected: 0,
    }

    getComercios = async () => {
        const authData = this.props.userToken
        var comercios = await client.getComercios(authData)
        this.setState({ comercios, comercios_filtered: comercios })
    }

    inhabilitarComercio = async (id) => {
        const authData = this.props.userToken
        const req = { id, ...authData };
        const res = await client.inhabilitar_comercio(req)
        if (res.ok){
            this.getComercios();
        }
    }


    async componentDidMount() {
        this.getComercios();
    }

    modalBoletasClose = () => this.setState({ modalBoletasShow: false });
    modalUsuariosClose = () => this.setState({ modalUsuariosShow: false });
    modalCrearUsuarioClose = () => this.setState({ modalCrearUsuarioShow: false, modalUsuariosShow: true });
    modalEditarComercioClose = () => this.setState({ modalEditarComercioShow: false });
    modalEditarDatosBancariosClose = () => this.setState({ modalEditarDatosBancariosShow: false });
    modalRegisterClose = () => {
        this.setState({ modalRegisterShow: false });
        this.getComercios();
    }
    modalSetearAuthorizationClose = () => this.setState({ modalSetearAuthorizationShow: false });


    modalBoletasOpen = (id) => this.setState({ modalBoletasShow: true, idComercioSelected: id });
    modalUsuariosOpen = (id) => this.setState({ modalUsuariosShow: true, idComercioSelected: id });
    modalCrearUsuarioOpen = () => this.setState({ modalCrearUsuarioShow: true, modalUsuariosShow: false });
    modalEditarComercioOpen = (id) => this.setState({ modalEditarComercioShow: true, idComercioSelected: id });
    modalEditarDatosBancariosOpen = (id) => this.setState({ modalEditarDatosBancariosShow: true, idComercioSelected: id });
    modalRegisterOpen = () => this.setState({ modalRegisterShow: true });
    modalSetearAuthorizationOpen = (id) => this.setState({ modalSetearAuthorizationShow: true, idComercioSelected: id});


    modalCambiarContrasenaClose = () => this.setState({ modalCambiarContrasenaShow: false });
    modalCambiarContrasenaOpen = (email) => this.setState({ modalCambiarContrasenaShow: true, emailSelected: email });

    handleComercioSearch = (s) => {
        let currentList = [];
        let newList = [];

        if (s.target.value !== "") {
            currentList = this.state.comercios;

            newList = currentList.filter( (c) => {
                const nombre = c.nombre.toLowerCase();
                const numero = c.numero;
                const rut = c.rut_contribuyente;
                const filter = s.target.value.toLowerCase();
                if (nombre.includes(filter) || numero.includes(filter) || rut.includes(filter)){
                    return c;
                }
            });
        } else {
            newList = this.state.comercios;
        }

        this.setState({ comercios_filtered: newList});
    }


    render(){
        return(
            <div>
                <Navbar bg="light" variant="light">
                    <Navbar.Brand>Comercios</Navbar.Brand>
                    <Nav className="mr-sm-2">
                    </Nav>
                    <Form inline>
                        <FormControl type="text" placeholder="Search" onChange={this.handleComercioSearch} className="mr-sm-2" />
                        {
                            this.props.isAdmin
                            ? (<Button variant="secondary" onClick={ () => this.modalRegisterOpen() }>Crear Comercio</Button>)
                            : null
                        }                    
                    </Form>
                </Navbar>

                <RegisterModal
                    show={this.state.modalRegisterShow}
                    onHide={this.modalRegisterClose}
                    updateusuarios={() => this.getComercios}
                />

                <CambiarContrasenaComercioModal
                    show={this.state.modalCambiarContrasenaShow}
                    onHide={this.modalCambiarContrasenaClose}
                    userToken={this.props.userToken}
                    email={this.state.emailSelected}
                />

                <SetearAuthorizationModal
                    show={this.state.modalSetearAuthorizationShow}
                    onHide={this.modalSetearAuthorizationClose}
                    userToken={this.props.userToken}
                    id={this.state.idComercioSelected}
                    updateusuarios={() => this.getComercios}
                />

                <EditarDatosBancariosModal
                    show={this.state.modalEditarDatosBancariosShow}
                    id={this.state.idComercioSelected}
                    onHide={this.modalEditarDatosBancariosClose}
                    userToken={this.props.userToken}
                />

                <NewUsuarioComercioModal
                    show={this.state.modalCrearUsuarioShow}
                    onHide={this.modalCrearUsuarioClose}
                    userToken={this.props.userToken}
                    id={this.state.idComercioSelected} 
                />

                <BoletasModal
                    show={this.state.modalBoletasShow}
                    onHide={this.modalBoletasClose}
                    userToken={this.props.userToken}
                    id={this.state.idComercioSelected}
                />

                <UsuariosComerciosModal 
                    show={this.state.modalUsuariosShow}
                    onHide={this.modalUsuariosClose}
                    userToken={this.props.userToken}
                    id={this.state.idComercioSelected}
                    newuser={this.modalCrearUsuarioOpen}
                />

                <Table responsive >
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Email</th>
                        <th>Numero de teléfono</th>
                        <th>Dirección</th>
                        <th>RUT Contribuyente</th>
                        <th>Nombre</th>
                        <th>Saldo</th>
                        <th>Fecha de creación</th>
                        <th>Fecha de modificación</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.state.comercios_filtered.map((c) => {
                            return (
                                <tr key={c.id}>
                                    <td>{c.id}</td>
                                    <td>{c.email}</td>
                                    <td>{c.numero}</td>
                                    <td>{c.direccion}</td>
                                    <td>{c.rut_contribuyente}</td>
                                    <td>{c.nombre}</td>
                                    <td>{c.ultimo_saldo}</td>
                                    <td>{c.created_at}</td>
                                    <td>{c.updated_at}</td>
                                    <td>
                                        <Button variant="secondary" onClick={() => this.modalBoletasOpen(c.id)}>Ver boletas</Button>
                                        <Button variant="secondary" onClick={() => this.modalEditarDatosBancariosOpen(c.id)}>Datos Bancarios</Button>
                                        { this.props.isAdmin 
                                            ? ( <Button variant="secondary" onClick={() => this.modalUsuariosOpen(c.id)}>Ver Vendedores</Button>)
                                            : null
                                        }
                                        { this.props.isAdmin 
                                            ? ( <Button variant="secondary" onClick={() => this.modalCambiarContrasenaOpen(c.email)}>Cambiar Contraseña</Button>)
                                            : null
                                        }
                                        { (this.props.isAdmin && c.habilitado)    
                                            ? (<Button variant="secondary" onClick={() => this.inhabilitarComercio(c.id)}>Inhabilitar</Button>)
                                            : (<Button variant="secondary" onClick={() => this.modalSetearAuthorizationOpen(c.id)}>Habilitar</Button>)
                                        }
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </Table>
            </div>
        )
    }
}

class ClientesTable extends React.Component{

    state={
        clientes : [],
        clientes_filtered : [],
        idClienteSelected : 0
    }

    async componentDidMount() {
        this.getClientes();
    }

    getClientes = async () => {
        const authData = this.props.userToken
        var clientes = await client.getClientes(authData)
        this.setState({ clientes, clientes_filtered: clientes })
    }

    handleClienteSearch = (s) => {
        let currentList = [];
        let newList = [];

        if (s.target.value !== "") {
            currentList = this.state.clientes;

            newList = currentList.filter( (c) => {
                const nombre = c.nombre.toLowerCase();
                const numero = c.numero;
                const rut = c.rut;
                const filter = s.target.value.toLowerCase();
                if (nombre.includes(filter) || numero.includes(filter) || rut.includes(filter)){
                    return c;
                }
            });
        } else {
            newList = this.state.clientes;
        }
        this.setState({ clientes_filtered: newList});
    }

    render(){
        return(
            <div>
                <Navbar bg="light" variant="light">
                    <Navbar.Brand>Clientes</Navbar.Brand>
                    <Nav className="mr-sm-2">
                    </Nav>
                    <Form inline>
                        <FormControl type="text" placeholder="Search" onChange={this.handleClienteSearch} className="mr-sm-2" />
                    </Form>
                </Navbar>
                <Table responsive>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Email</th>
                            <th>Numero de teléfono</th>
                            <th>RUT</th>
                            <th>Nombre</th>
                            <th>Saldo</th>
                            <th>Fecha de creación</th>
                            <th>Fecha de modificación</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.state.clientes_filtered.map((c) => {
                                return (
                                    <tr key={c.id}>
                                        <td>{c.id}</td>
                                        <td>{c.email}</td>
                                        <td>{c.numero}</td>
                                        <td>{c.rut}</td>
                                        <td>{c.nombre}</td>
                                        <td>{c.ultimo_saldo}</td>
                                        <td>{c.created_at}</td>
                                        <td>{c.updated_at}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>
            </div>
        )
    }
}

class TransaccionesTable extends React.Component{

    state = {
        transacciones: [],
        transacciones_filtered: [],
        modalDepositoShow: false,
        rutTransaccionSelected: 0,
        montoTransaccionSelected: 0,
        idTransaccionSelected: 0,
    }

    async componentDidMount() {
        this.getTransacciones();
    }

    
    modalDepositoClose = () => this.setState({ modalDepositoShow: false });

    modalDepositoOpen = (rut, monto) => this.setState({ modalDepositoShow: true, rutTransaccionSelected: rut, montoTransaccionSelected: monto });

    handleTransaccionSearch = (s) => {
        let currentList = [];
        let newList = [];

        if (s.target.value !== "") {
            currentList = this.state.transacciones;

            newList = currentList.filter( (t) => {
                const rut = t.rut_solicitante;
                const monto = String(t.monto);
                const filter = s.target.value.toLowerCase();
                if (rut.includes(filter) || monto.includes(filter)){
                    return t;
                }
            });
        } else {
            newList = this.state.transacciones;
        }
        this.setState({ transacciones_filtered: newList});
    }

    sortByState = () => {
        this.setState({
            transacciones: this.state.transacciones.sort((a, b) => {
                if (a.realizado < b.realizado) {
                    return -1;
                } else if (a.realizado > b.realizado) {
                    return 1;
                } else {
                    return 0;
                }
            })
        });
    }

    getTransacciones = async () => {
        const authData = this.props.userToken
        var transacciones = await client.getTransacciones(authData)
        this.setState({ transacciones, transacciones_filtered: transacciones })
        this.sortByState();
    }

    actualizarTransaccion = async (id) => {
        const authData = this.props.userToken
        const req = { id, ...authData };
        const res = await client.actualizar_transaccion(req)
        if (res.ok){
            this.getTransacciones();
        }
    }

    render(){
        return(
            <div>
                <Navbar bg="light" variant="light">
                    <Navbar.Brand>Transacciones</Navbar.Brand>
                    <Nav className="mr-sm-2">
                    </Nav>
                    <Form inline>
                        <FormControl type="text" placeholder="Search" onChange={this.handleTransaccionSearch} className="mr-sm-2" />
                    </Form>
                </Navbar>

                
                <DatosBancariosModal
                    show={this.state.modalDepositoShow}
                    onHide={this.modalDepositoClose}
                    userToken={this.props.userToken}
                    rut={this.state.rutTransaccionSelected}
                    monto={this.state.montoTransaccionSelected}
                /> 

                <Table responsive>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Tipo</th>
                                <th>RUT</th>
                                <th>Monto</th>
                                <th>Estado</th>
                                <th>Fecha de creación</th>
                                <th>Fecha de modificación</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.transacciones_filtered.map((t) => {
                                    return (
                                        
                                        <tr key={t.id}>
                                            <td>{t.id}</td>
                                            <td>{t.tipo}</td>
                                            <td>{t.rut_solicitante}</td>
                                            <td>{t.monto}</td>
                                            <td>{t.realizado}</td>
                                            <td>{t.created_at}</td>
                                            <td>{t.updated_at}</td>
                                            <td>
                                                {
                                                    (t.tipo === "retiro" && t.realizado === 0)
                                                        ? (<Button variant="secondary" onClick={() => this.modalDepositoOpen(t.rut_solicitante, t.monto)}>Ver Datos Bancarios</Button>)
                                                        : null
                                                }
                                                {
                                                    (t.realizado === 0)
                                                    ? (<Button variant="secondary" onClick={() => this.actualizarTransaccion(t.id)}>Confirmar Transacción</Button>)
                                                    : null
                                                }
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                </div>
        )
    }
}

export default Home;