import React from 'react';
import { Modal } from 'react-bootstrap'
import SetearAuthorization from './SetearAuthorization'

class SetearAuthorizationModal extends React.Component {
    render() {
        return (
            <Modal
                {...this.props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Definir Código Autorización
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <SetearAuthorization userToken={this.props.userToken} id={this.props.id} updateusuarios={() => this.props.updateusuarios} />
                </Modal.Body>
            </Modal>
        );
    }
}

export default SetearAuthorizationModal;