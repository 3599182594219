
import React from 'react';
import { Modal } from 'react-bootstrap'
import DatosBancarios from './DatosBancarios'

class DatosBancariosModal extends React.Component {
    render() {
        return (
            <Modal
                {...this.props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Datos Bancarios                    
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <DatosBancarios userToken={this.props.userToken} rut={this.props.rut} monto={this.props.monto} />
                </Modal.Body>
            </Modal>
        );
    }
}

export default DatosBancariosModal;